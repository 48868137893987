import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { NewSupplier, Supplier } from '@dougs/suppliers/dto';

@Injectable({
  providedIn: 'root',
})
export class SupplierHttpService {
  constructor(private readonly http: HttpClient) {}

  getSuppliers(companyId: number): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/suppliers?limit=1000&offset=0`,
    );
  }

  updateSupplier(supplier: Supplier): Observable<Supplier> {
    return this.http.post<Supplier>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${supplier.companyId}/suppliers/${supplier.id}`,
      supplier,
    );
  }

  addSupplier(companyId: number, supplier: NewSupplier): Observable<Supplier> {
    return this.http.post<Supplier>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/suppliers`,
      supplier,
    );
  }

  deleteSupplier(supplier: Supplier): Observable<Supplier> {
    return this.http.delete<Supplier>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${supplier.companyId}/suppliers/${supplier.id}`,
    );
  }
}
